<template>
  <div class="loader-container">
    <div class="loader">Loading...</div>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>